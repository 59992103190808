<template>
  <v-container class="py-7">
    <catalog-sections v-if="child" :parent="sectionID" />
    <product-card
      v-for="(product, i) in products[sectionID]"
      :key="i"
      :product="product"
      :section="sectionID"
    />
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ProductCard from '@/components/ProductCard'
import CatalogSections from '@/components/CatalogSections'

export default {
  name: 'Section',

  components: {
    ProductCard,
    CatalogSections,
  },

  computed: {
    sectionID() {
      return this.$route.params.section
    },

    section() {
      return this.sections.find((s) => s.cat_id == this.sectionID)
    },

    child() {
      return this.section?.child
    },

    ...mapState('catalog', ['products', 'sections']),
  },

  methods: {
    ...mapActions('catalog', ['loadProducts']),
  },

  watch: {
    '$route.params.section'() {
      this.loadProducts(this.sectionID)
    },
  },

  created() {
    this.loadProducts(this.sectionID)
  },

  mounted() {
    document.querySelector('title').innerText =
      this.section?.seo_title || this.section?.category_name
    this.$emitter.emit('menu-view')
  },
}
</script>
