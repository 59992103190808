<template>
  <v-card class="product-card mb-4" color="white" height="116">
    <div class="d-flex flex-no-wrap">
      <div class="avatar" @click="toDetailPage">
        <div
          v-if="product.sticker_name"
          class="product-label"
          :style="`background-color: ${product.sticker_color};`"
        >
          <span>{{ product.sticker_name }}</span>
        </div>
        <v-img :src="product.photo" class="rounded-tl rounded-bl" />
      </div>

      <div
        v-ripple="{ class: 'primary--text' }"
        @click="productAction"
        class="product-body"
      >
        <div class="product-name dark--text">
          {{ product.item_name | truncate(30, '...') }}
        </div>
        <div
          v-html="product.item_description"
          class="product-desc dark--text"
        ></div>
        <v-row class="mt-auto flex-grow-0 flex-nowrap" dense align="end">
          <v-col>
            <div class="product-price-old error--text" v-if="discount">
              {{ basePrice }} {{ $store.getters['app/currency'] }}
            </div>
            <div class="product-price dark--text" v-if="currentPrice">
              <span v-if="hasVariations" class="has-variations">от</span>
              {{ currentPrice }} {{ $store.getters['app/currency'] }}
            </div>
          </v-col>
          <v-col cols="auto">
            <v-btn
              v-if="notAvailable"
              small
              depressed
              class="product-button mr-1"
            >
              Не доступен
            </v-btn>
            <v-btn
              v-else-if="productIsSimple"
              class="product-button mr-1"
              color="primary"
              small
              depressed
              :loading="loading"
              @click.stop="addToCart"
              >В корзину</v-btn
            >
            <v-btn
              v-else
              class="product-button mr-1"
              color="primary"
              small
              depressed
              outlined
              >Выбрать</v-btn
            >
          </v-col>
        </v-row>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  props: ['product', 'section'],

  data: () => ({
    priceIndex: 0,
    loading: false,
  }),

  computed: {
    productIsSimple() {
      return !this.hasVariations && !this.product.addon_item
    },

    detailPageLink() {
      return {
        name: 'Product',
        params: {
          section: this.$route.params.section,
          product: this.product.item_id,
        },
      }
    },

    hasVariations() {
      return this.product.prices && this.product.prices.length > 1
    },

    discount() {
      const i = this.priceIndex
      const prices = this.product.prices
      const discount = prices && prices[i] && prices[i].discount
      return Number(discount) || 0
    },

    basePrice() {
      const i = this.priceIndex
      const prices = this.product.prices
      const price = prices && prices[i] && prices[i].price
      return this.$price.roundupPrice(Number(price)) || 0
    },

    currentPrice() {
      return this.$price.roundupPrice(this.basePrice - this.discount)
    },

    notAvailable() {
      return (
        !this.product.prices || Object.is(+this.product?.not_available || 1, 2)
      )
    },
  },

  methods: {
    toDetailPage() {
      this.$router.push(this.detailPageLink)
    },

    productAction() {
      /* this.productIsSimple
        ? this.addToCart()
        : this.$router.push(this.detailPageLink); */
      this.$router.push(this.detailPageLink)
    },

    async addToCart() {
      const { commit, dispatch } = this.$store
      this.loading = true

      const params = {
        category_id: this.section,
        item_id: this.product.item_id,
        qty: 1,
        price: this.basePrice,
      }

      const result = await dispatch('checkout/addItem', params)
      this.$emitter.emit('product-add')
      let message
      if (result.code == 1) {
        message = `Добавлено: ${this.product.item_name}`
      } else {
        message = result.msg || 'Ошибка при добавлении в корзину'
      }
      commit('snackbar/update', { active: true, text: message })
      this.loading = false
    },
  },
}
</script>

<style lang="scss" scoped>
.product-label {
  top: 0;
  left: 0;
  bottom: 0;
  position: absolute;
  color: #fff;
  z-index: 1;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  white-space: nowrap;
  letter-spacing: 0.05em;
  border-radius: inherit;

  span {
    transform: rotate(-90deg);
  }
}

.product-card {
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1) !important;
  line-height: 1;

  .avatar {
    height: 116px;
    width: 116px;
    border-radius: $border-radius-root 0 0 $border-radius-root;
  }

  .v-image {
    height: 100%;
  }
}

.product-body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 12px 16px 8px;

  @media (max-width: 359.98px) {
    padding: 8px 8px 6px;
  }
}

.product-name {
  font-weight: 900;
  font-size: 16px;
  margin: 0 0 4px;
}

.product-desc {
  font-size: 12px;
  font-weight: 400;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.product-button {
  font-weight: 400;
  border-radius: 8px;
}

.product-price {
  font-weight: 600;
  font-size: 16px;
  white-space: nowrap;
}

.product-price-old {
  text-decoration: line-through;
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
}

.has-variations {
  font-size: 12px;
  font-weight: 400;
}
</style>
